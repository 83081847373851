import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseTimelineFormValues, getDaysElapsedError, requiredFieldMsg, SudoRequiredField} from "./TimelineEventForm";
import moment from "moment";
import {isString} from "formik";
import {Moment} from "moment/moment";

export const TRANSITION_STRING = 'Transition';
export type TRANSITION = typeof TRANSITION_STRING;

export type TransitionValues = BaseTimelineFormValues & {
    'DemoEntry to Timeline by AN to TrnAN::ActionNeeded': string;
    'DemoEntry to Timeline by AN to TrnAN::ANCompletedDate': string;
    'DemoEntry to Timeline by AN to TrnAN::ANFollowUpDate': string;
    TrnPartCtoPartBReferralDate: string;
    TrnTransitionMeetingDate: string;
    TrnPartCServCoord: string;
    TrnPartCProvider: string;
    TrnParentNotAtConference: string;
    TrnParentParticipationMethod: string;
    TrnRefPacketReceivedDate: string;
    TrnConsentRecordTransferDate: string;
    TrnLEANotAtConference: string;
    TrnLEAParticipationMethod: string;
    TrnConsentForReferralDate: string;
    TrnNote: string;
    TrnNotes: string;
};

const endOfToday = moment().add(1, 'day').endOf('day').toDate();

export const getTransitionSchema = (oldestDate : Moment) => ({
    'DemoEntry to Timeline by AN to TrnAN::ActionNeeded': yup.string(),
    'DemoEntry to Timeline by AN to TrnAN::ANCompletedDate': yupDateRegex,
    'DemoEntry to Timeline by AN to TrnAN::ANFollowUpDate': yupDateRegex,
    TrnPartCtoPartBReferralDate: yup.date().min(
        oldestDate.toDate(), 'Part C to Part B Transition Notification Date must be later than ' +
        oldestDate.format('MM/DD/YYYY')
    ).max(
        endOfToday,
        'Date Must Not Be In The Future.'
    ),
    TrnTransitionMeetingDate: yup.date().min(
        oldestDate.toDate(), 'Date must be later than ' +
        oldestDate.format('MM/DD/YYYY')
    ).max(
        endOfToday,
        'Date Must Not Be In The Future.'
    ),
    TrnPartCServCoord: yup.string(),
    TrnPartCProvider: yup.string(),
    TrnParentNotAtConference: yup.string(),
    TrnParentParticipationMethod: yup.string(),
    TrnRefPacketReceivedDate: yupDateRegex,
    TrnConsentRecordTransferDate: yupDateRegex,
    TrnLEANotAtConference: yup.string(),
    TrnLEAParticipationMethod: yup.string(),
    TrnConsentForReferralDate: yupDateRegex,
    TrnNote: yup.string(),
    TrnNotes: yup.string()
});

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
    sudoRequiredFields: Array<SudoRequiredField>;
};

const TransitionForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed,
    sudoRequiredFields
} : Props) => {

    let transitionMeetingDateError = sudoRequiredFields.find(
        (sudoRequiredField) =>
            sudoRequiredField.name === 'TrnTransitionMeetingDate'
    )?.showError ? requiredFieldMsg['TrnTransitionMeetingDate'] : '';

    transitionMeetingDateError += sudoRequiredFields.find(
        (sudoRequiredField) =>
            sudoRequiredField.name === 'TrnTransitionMeetingDateAge'
    )?.showError ? requiredFieldMsg['TrnTransitionMeetingDateAge'] : '';

    let partCtoBReferralDateError = getDaysElapsedError(
        'TrnPartCtoPartBReferralDate',
        negativeDaysElapsed,
        sudoRequiredFields
    );

    partCtoBReferralDateError += sudoRequiredFields.find(
        (sudoRequiredField) =>
            sudoRequiredField.name === 'TrnPartCtoPartBReferralDateAge'
    )?.showError ? requiredFieldMsg['TrnPartCtoPartBReferralDateAge'] : '';

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={6}>
                    <StandardField
                        name="TrnPartCtoPartBReferralDate"
                        type="date"
                        label="Part C to Part B Transition Notification Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={(value) => {
                            handleCheckNegativeDaysElapsed(TRANSITION_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={partCtoBReferralDateError}
                    />
                </Col>
                <Col xs={6}>
                    <StandardField
                        name="TrnTransitionMeetingDate"
                        type="date"
                        label="ES Transition Conference Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        errorNoticeOverride={transitionMeetingDateError}

                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnPartCServCoord"
                        type="text"
                        label="Part C Service Coordinator"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnRefPacketReceivedDate"
                        type="date"
                        label="Date Referral Packet Received from ES"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnConsentForReferralDate"
                        type="date"
                        label="Date of ES Consent For Referral"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnPartCProvider"
                        type="text"
                        label="Part C Provider"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnConsentRecordTransferDate"
                        type="date"
                        label="Date of ES Consent For Record Transfer"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnParentNotAtConference"
                        type="radio"
                        label="Parent Participated Transition Conference"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'YesNo',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnLEANotAtConference"
                        type="radio"
                        label="LEA Participated Transition Conference"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'YesNo',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnParentParticipationMethod"
                        type="select"
                        label="Parent Participation Method"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'TrnParentParticipationMethod',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnLEAParticipationMethod"
                        type="select"
                        label="LEA Participation Method"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'TrnParticipationMethod',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="TrnNote"
                        type="textarea"
                        label="Notes"
                        disabled={!canSave}
                        className="textarea-lg"
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TransitionForm;

import React, {ReactNode, useCallback, useDebugValue, useState} from 'react';
import {apiEndpoint} from "../../utils/api";
import {ChildTracking} from "../Tracking/Tracking";
import {ActiveTab, Child, List, mapRawChildDemographics} from "../../pages/RecordLocator";
import {useApiFetch} from "./OidcProvider";
import {mapRawLists} from "./ChrisUserProvider";

export type SearchResult = {
    childUUID: string;
    dbnum: number;
    lastName: string;
    firstName: string;
    birthDate: string;
    code: string;
    countyOfResidence: string;
    parentLastName: string;
    canRead: boolean;
    siteContact: string;
    sitePhone: string;
    siteContactEmail: string;
    demographics: JSX.Element;
    tracking: JSX.Element;
    cosForm: true;
};

export type SearchOptions = {
    dbnum: string;
    lastName: string;
    firstName: string;
    birthDate: string;
    birthDateEnd: string;
    'Addresses::AdultFirstName': string;
    'Addresses::AdultLastName': string;
    'Addresses::AdultPermStreet': string;
    'Addresses::AdultStreet': string;
    'Addresses::AdultPhone1': string;
    'Addresses::AdultPhone2': string;
    'Addresses::AdultPhone3': string;
    'Addresses::AdultEmail': string;
    'DemoEntry::StaffAssignedFullName': string;
    'DemoEntry::HomeZonedSchool': string;
    'DemoEntry to ServiceCoordination by Appt Event::ApptDate': string;
    'StudentNumber': string;
};

export type ChildSummary = {
    dob: string;
    age: string;
    lastName: string;
    suffix: string;
    firstName: string;
    middleName: string;
    SY5: string;
    childCurrently: string;
    name: string;
    thirdBirthday: string;
    primaryLanguage: string;
    countyOfResidence: string;
    location: string;
    subLocation: string;
    updateDate: string;
};

type Props = {
    children ?: ReactNode;
};

const defaultSearchOptions = {
    dbnum: '',
    lastName: '',
    firstName: '',
    birthDate: '',
    birthDateEnd: '',
    'Addresses::AdultFirstName': '',
    'Addresses::AdultLastName': '',
    'Addresses::AdultPermStreet': '',
    'Addresses::AdultStreet': '',
    'Addresses::AdultPhone1': '',
    'Addresses::AdultPhone2': '',
    'Addresses::AdultPhone3': '',
    'Addresses::AdultEmail': '',
    'DemoEntry::StaffAssignedFullName': '',
    'DemoEntry::HomeZonedSchool': '',
    'DemoEntry to ServiceCoordination by Appt Event::ApptDate': '',
    'StudentNumber': ''
};

const useStateWithLabel = (initialValue: any, name: any): [boolean, (loading: boolean) => void] => {
    const [value, setValue] = useState(initialValue);
    useDebugValue(`${name}: ${value}`);
    return [value, setValue];
};

const mapRawTracking = (values: any): ChildTracking => ({
    ...values,
    trackingGeneral: {
        ...values.trackingGeneral,
        UpdateDate: values.trackingGeneral.UpdateDate,
        ThirdBDay: values.trackingGeneral.ThirdBDay,
        InterAgencyStartDate: values.trackingGeneral.InterAgencyStartDate,
        InterAgencyEndDate: values.trackingGeneral.InterAgencyEndDate
    }
});


export const RecordLocatorProviderContext = React.createContext<{
    searchOptions : SearchOptions;
    setSearchOptions : (a : SearchOptions) => void;
    searchResults : SearchResult[] | null;
    setSearchResults : (a : SearchResult[] | null) => void;
    tooManyResults : boolean;
    setTooManyResults : (a : boolean) => void;
    showCosTooOldModal : boolean;
    setShowCosTooOldModal : (a : boolean) => void;
    cosChildUUID : string;
    setCosChildUUID : (a : string) => void;
    possibleDuplicates : boolean;
    setPossibleDuplicates : (a : boolean) => void;
    showGeneralErrorModal : boolean;
    setShowGeneralErrorModal : (a : boolean) => void;
    showRecordTransferModal : boolean;
    setShowRecordTransferModal : (a : boolean) => void;
    transferChild : SearchResult | null;
    setTransferChild : (a : SearchResult) => void;
    handleClearSearchForm : () => void;
    handleShowTransferRecordModal : (show: boolean, child: SearchResult | null) => void;
    loading : boolean;
    setLoading : (a : boolean) => void;
    childSummary : ChildSummary | null;
    setChildSummary : (a : ChildSummary | null) => void;
    tracking : ChildTracking | null;
    setTracking : (a : ChildTracking | null) => void;
    activeTab : ActiveTab | null;
    setActiveTab : (a : ActiveTab | null) => void;
    activeChild : Child | null;
    setActiveChild : (a : Child | null) => void;
    showChildSearchHelperModal : boolean;
    setShowChildSearchHelperModal : (a : boolean) => void;
    siteLists : List[]
    setSiteLists : (a : List[]) => void;
    handleSetActiveChild : (childUUID: string, activeTab: ActiveTab) => Promise<void>;
    handleShowChildSearchHelperModal : (a : boolean) => void;
    handelClearActiveChild : () => void;
}>({
    searchOptions : defaultSearchOptions,
    setSearchOptions : (a : SearchOptions) => {},
    searchResults : null,
    setSearchResults : (a : SearchResult[] | null) => {},
    tooManyResults : false,
    setTooManyResults : (a : boolean) => {},
    showCosTooOldModal : false,
    setShowCosTooOldModal : (a : boolean) => {},
    cosChildUUID : '',
    setCosChildUUID : (a : string) => {},
    possibleDuplicates : false,
    setPossibleDuplicates : (a : boolean) => {},
    showGeneralErrorModal : false,
    setShowGeneralErrorModal : (a : boolean) => {},
    showRecordTransferModal : false,
    setShowRecordTransferModal : (a : boolean) => {},
    transferChild : null,
    setTransferChild : (a : SearchResult) => {},
    handleClearSearchForm: () => {},
    handleShowTransferRecordModal : (show: boolean, child: SearchResult | null) => {},
    loading : false,
    setLoading : (a : boolean) => {},
    childSummary : null,
    setChildSummary : (a : ChildSummary | null) => {},
    tracking : null,
    setTracking : (a : ChildTracking | null) => {},
    activeTab : null,
    setActiveTab : (a : ActiveTab | null) => {},
    activeChild : null,
    setActiveChild: (a : Child | null) => {},
    showChildSearchHelperModal : false,
    setShowChildSearchHelperModal : (a : boolean) => {},
    siteLists : [],
    setSiteLists : (a : List[]) => {},
    handleSetActiveChild : async (childUUID: string, activeTab: ActiveTab) => {},
    handleShowChildSearchHelperModal : (a : boolean) => {},
    handelClearActiveChild : () => {},
});

const RecordLocatorProvider : React.FC<Props> = ({children} : Props) => {
    const apiFetch = useApiFetch();
    const [searchResults, setSearchResults] = useState<SearchResult[] | null>(null);
    const [searchOptions, setSearchOptions] = useState<SearchOptions>(defaultSearchOptions);
    const [tooManyResults, setTooManyResults] = useState<boolean>(false);
    const [showCosTooOldModal, setShowCosTooOldModal] = useState<boolean>(false);
    const [cosChildUUID, setCosChildUUID] = useState<string>("");
    const [possibleDuplicates, setPossibleDuplicates] = useState<boolean>(false);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);
    const [showRecordTransferModal, setShowRecordTransferModal] = useState<boolean>(false);
    const [transferChild, setTransferChild] = useState<SearchResult | null>(null);
    const [loading, setLoading] = useStateWithLabel(false, 'loading');
    const [childSummary, setChildSummary] = useState<ChildSummary | null>(null);
    const [tracking, setTracking] = useState<ChildTracking | null>(null);
    const [activeTab, setActiveTab] = useState<ActiveTab | null>('demographics');
    const [activeChild, setActiveChild] = useState<Child | null>(null);
    const [showChildSearchHelperModal, setShowChildSearchHelperModal] = useState<boolean>(false);
    const [siteLists, setSiteLists] = useState<List[]>([]);

    const handleShowTransferRecordModal = useCallback((show: boolean, child: SearchResult | null) => {
        setShowRecordTransferModal(show);
        setTransferChild(child);
    }, [setShowRecordTransferModal]);

    const loadTracking = useCallback(async (childUUID: string): Promise<ChildTracking> => {
        const url = new URL(`/v1/child/tracking/${childUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString());
        if (!response.ok) {
            setShowGeneralErrorModal(true);
        }
        const data = await response.json();
        const mappedTracking = mapRawTracking(data);

        setTracking(mappedTracking);
        return mappedTracking;
    }, [setTracking, apiFetch]);

    const handleClearSearchForm = useCallback(() => {
        setSearchOptions({
            dbnum: '',
            lastName: '',
            firstName: '',
            birthDate: '',
            birthDateEnd: '',
            'Addresses::AdultFirstName': '',
            'Addresses::AdultLastName': '',
            'Addresses::AdultPermStreet': '',
            'Addresses::AdultStreet': '',
            'Addresses::AdultPhone1': '',
            'Addresses::AdultPhone2': '',
            'Addresses::AdultPhone3': '',
            'Addresses::AdultEmail': '',
            'DemoEntry::StaffAssignedFullName': '',
            'DemoEntry::HomeZonedSchool': '',
            'DemoEntry to ServiceCoordination by Appt Event::ApptDate': '',
            'StudentNumber': ''
        });
        setSearchResults(null);
        setTooManyResults(false);
        setPossibleDuplicates(false);
    }, [setSearchOptions, setPossibleDuplicates, setTooManyResults, setSearchResults]);

    const handleShowChildSearchHelperModal = useCallback((show: boolean) => {
        setShowChildSearchHelperModal(show);
    }, [setShowChildSearchHelperModal]);

    const loadSiteLists = useCallback(async (siteUUID: string) => {
        const url = new URL(`/v1/lists/non-static/${siteUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString());
        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }
        const data = await response.json();

        setSiteLists(data.map(mapRawLists));
    }, [setSiteLists, apiFetch]);

    const handelClearActiveChild = useCallback(() => {
        setActiveChild(null);
        setActiveTab(null);
        setLoading(false);
    }, [setLoading])

    const handleSetActiveChild = useCallback(async (childUUID: string, activeTab: ActiveTab) => {
        setLoading(true);
        handleShowChildSearchHelperModal(false);

        const url = new URL(`/v1/child/demographics/${childUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString());

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        const mappedChild = mapRawChildDemographics(data);
        const tracking: ChildTracking = await loadTracking(childUUID);

        setChildSummary({
            dob: mappedChild.displayDOB,
            age: mappedChild.age,
            lastName: mappedChild.generalDemographics.LastName,
            suffix: mappedChild.generalDemographics.Suffix,
            firstName: mappedChild.generalDemographics.FirstName,
            middleName: mappedChild.generalDemographics.MiddleName,
            SY5: mappedChild.SY5,
            childCurrently: mappedChild.ageDetailedDisplay,
            name: mappedChild.name,
            thirdBirthday: tracking.trackingGeneral.ThirdBDay,
            primaryLanguage: tracking.trackingGeneral.PrimaryLanguage,
            location: mappedChild.generalDemographics.Location,
            subLocation: mappedChild.generalDemographics.SubLocation,
            countyOfResidence: mappedChild.generalDemographics.CountyOfResidence,
            updateDate: tracking.trackingGeneral.UpdateDate
        });

        if (mappedChild.siteUUID) {
            await loadSiteLists(mappedChild.siteUUID);
        }
        setActiveTab(activeTab);
        setActiveChild(mappedChild);
        setLoading(false);

    }, [setShowGeneralErrorModal, setActiveTab, apiFetch, loadTracking, setLoading, handleShowChildSearchHelperModal, loadSiteLists]);


    return (
        <RecordLocatorProviderContext.Provider value={{
            searchResults, setSearchResults,
            searchOptions, setSearchOptions,
            tooManyResults, setTooManyResults,
            showCosTooOldModal, setShowCosTooOldModal,
            cosChildUUID, setCosChildUUID,
            possibleDuplicates, setPossibleDuplicates,
            showGeneralErrorModal, setShowGeneralErrorModal,
            showRecordTransferModal, setShowRecordTransferModal,
            transferChild, setTransferChild,
            loading, setLoading,
            childSummary, setChildSummary,
            tracking, setTracking,
            activeTab, setActiveTab,
            activeChild, setActiveChild,
            showChildSearchHelperModal, setShowChildSearchHelperModal,
            siteLists, setSiteLists,
            handleClearSearchForm,
            handleShowTransferRecordModal,
            handleSetActiveChild,
            handleShowChildSearchHelperModal,
            handelClearActiveChild,
        }}>
            {children}
        </RecordLocatorProviderContext.Provider>
    );
};

export default RecordLocatorProvider;


import {useMemo} from "react";
import {getOptionsForField} from "../../utils/formHelpers";
import {List} from "../../pages/RecordLocator";
import type {StandardFieldOption} from "../Forms/Fields/StandardField";

export const useOptionListWithCurrentValue = (currentValue: string, listName : string, siteLists : List[]) => {
    return useMemo(() : StandardFieldOption[] => {
        const list =  getOptionsForField(listName, currentValue, siteLists);
        if (!currentValue) {
            return list;
        }

        const foundCount = list.find((c) => c.label === currentValue);
        if (!foundCount) {
            return [
                ...list,
                {label: currentValue, value: currentValue, checked: true}
            ];
        }
        return list;
    }, [currentValue, listName, siteLists]);
}
